@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
.outfit-font{
    font-family: 'Outfit', 'sans-serif';
}
.flex {
    display: flex
}

.justify-center {
    justify-content: center
}

.justify-space-between {
    justify-content: space-between
}

.align-center {
    align-items: center
}

.align-baseline {
    align-items: baseline
}

.gap-y-10 {
    gap: 10px 0
}

.gap-y-20 {
    gap: 20px 0
}

.gap-y-30 {
    gap: 30px 0
}

.gap-x-5 {
    gap: 0 5px
}

.gap-x-10 {
    gap: 0 10px
}

.gap-x-20 {
    gap: 0 20px
}

.gap-x-30 {
    gap: 0 30px
}

.testslider {
    color: #fff
}

* {
    -webkit-tap-highlight-color: transparent
}

html {
    font-size: 16px;
    font-family: Open Sans, sans-serif
}

body,
html {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #4d4c4f;
    line-height: 1.4;
    overflow-x: hidden;
    letter-spacing: .5px
}

body {
    font-size: 18px!important;
    font-family: Outfit, sans-serif
}

@media (max-width:640px) {
    body {
        font-size: 16px
    }
}

a {
    text-decoration: none;
    outline: none;
    border: 0
}

a:focus,
a:hover {
    text-decoration: none !important
}

button,
img {
    text-decoration: none;
    outline: none;
    border: 0
}

button {
    font-family: Open Sans, sans-serif
}

input[type=submit] {
    text-decoration: none;
    outline: none;
    border: 0
}

input,
select,
textarea {
    font-family: Open Sans, sans-serif
}

b,
strong {
    font-weight: 700
}

*,
:after,
:before {
    box-sizing: border-box
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
ul {
    padding: 0;
    margin: 0;
    font-weight: unset
}

.container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 15px
}

@media (max-width:1600px) {
    .container {
        max-width: 1400px
    }
}

@media (max-width:1440px) {
    .container {
        max-width: 1200px
    }
}

.container-fluid {
    max-width: 95%;
    margin: 0 auto;
    padding: 0 15px
}

.container.small {
    max-width: 850px;
    padding: 0 20px
}

.container.medium {
    max-width: 1000px;
    padding: 0 20px
}

.pull-left {
    float: left !important
}

.pull-right {
    float: right !important
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.text-justify {
    text-align: justify
}

.text-uppercase {
    text-transform: uppercase
}

.text-lowercase {
    text-transform: lowercase
}

.text-italic {
    font-style: italic
}

.text-underline {
    text-decoration: underline
}

.text-light {
    font-weight: 300
}

.text-medium {
    font-weight: 400
}

.text-bold {
    font-weight: 600
}

.text-bolder {
    font-weight: 700
}

.text-black {
    color: #000
}

.text-white {
    color: #fff !important
}

.text-lightblue {
    color: #4a8af8 !important
}

.text-orange {
    color: #ee6e25
}

.img-responsive {
    max-width: 100%;
    height: auto
}

.img-full {
    width: 100%;
    height: auto
}

.center-block {
    margin: 0 auto
}

.bg-white {
    background-color: #fff
}

.bg-off-white {
    background-color: #f8f8fa
}

.bg-cover {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50%
}

.col-1 {
    width: 8.3333333333%;
    float: left
}

.col-2 {
    width: 16.6666666667%;
    float: left
}

.col-3 {
    width: 25%;
    float: left
}

.col-4 {
    width: 33.3333333333%;
    float: left
}

.col-5 {
    width: 41.6666666667%;
    float: left
}

.col-6 {
    width: 50%;
    float: left
}

.col-7 {
    width: 58.3333333333%;
    float: left
}

.col-8 {
    width: 66.6666666667%;
    float: left
}

.col-9 {
    width: 75%;
    float: left
}

.col-10 {
    width: 83.3333333333%;
    float: left
}

.col-11 {
    width: 91.6666666667%;
    float: left
}

.col-12 {
    width: 100%;
    float: left
}

.col-12-5 {
    width: 20%;
    float: left
}

.w100 {
    width: 100%
}

.w80 {
    width: 80%
}

.w70 {
    width: 70%
}

.w30,
.w70 {
    float: left
}

.w30 {
    width: 30%
}

.w20 {
    width: 20%;
    float: left
}

.cred {
    color: red
}

.h100 {
    height: 100%
}

.mg-5 {
    margin: 5px
}

.mg-10 {
    margin: 10px
}

.mg-20 {
    margin: 20px
}

.mg-30 {
    margin: 30px
}

.mg-tp-5 {
    margin-top: 5px
}

.mg-tp-10 {
    margin-top: 10px
}

.mg-tp-20 {
    margin-top: 20px
}

.mg-tp-30 {
    margin-top: 30px
}

.mg-bt-5 {
    margin-bottom: 5px
}

.mg-bt-10 {
    margin-bottom: 10px
}

.mg-bt-20 {
    margin-bottom: 20px
}

.mg-bt-30 {
    margin-bottom: 30px
}

.mg-rt-5 {
    margin-right: 5px
}

.mg-rt-10 {
    margin-right: 10px
}

.mg-rt-20 {
    margin-right: 20px
}

.mg-rt-30 {
    margin-right: 30px
}

.mg-lt-5 {
    margin-left: 5px
}

.mg-lt-10 {
    margin-left: 10px
}

.mg-lt-20 {
    margin-left: 20px
}

.mg-lt-30 {
    margin-left: 30px
}

.my-20 {
    margin: 20px 0
}

.my-10 {
    margin: 10px 0
}

.my-5 {
    margin: 5px 0
}

.mx-20 {
    margin: 0 20px
}

.mx-10 {
    margin: 0 10px
}

.mx-5 {
    margin: 0 5px
}

.pd-5 {
    padding: 5px
}

.pd-10 {
    padding: 10px
}

.pd-20 {
    padding: 20px
}

.pd-30 {
    padding: 30px
}

.pd-tp-5 {
    padding-top: 5px
}

.pd-tp-10 {
    padding-top: 10px
}

.pd-tp-20 {
    padding-top: 20px
}

.pd-tp-30 {
    padding-top: 30px
}

.pd-bt-5 {
    padding-bottom: 5px
}

.pd-bt-10 {
    padding-bottom: 10px
}

.pd-bt-20 {
    padding-bottom: 20px
}

.pd-bt-30 {
    padding-bottom: 30px
}

.pd-rt-5 {
    padding-right: 5px
}

.pd-rt-10 {
    padding-right: 10px
}

.pd-rt-20 {
    padding-right: 20px
}

.pd-rt-30 {
    padding-right: 30px
}

.pd-lt-5 {
    padding-left: 5px
}

.pd-lt-10 {
    padding-left: 10px
}

.pd-lt-20 {
    padding-left: 20px
}

.pd-lt-30 {
    padding-left: 30px
}

.h500 {
    height: 500px
}

.h600 {
    height: 600px
}

.h700 {
    height: 700px
}

.h800 {
    height: 800px
}

.pos-rel {
    position: relative
}

.pos-abs {
    position: absolute
}

.pos-fix {
    position: fixed
}

.bottom {
    bottom: 0
}

.top {
    top: 0
}

.left {
    left: 0
}

.right {
    right: 0
}

.visible-md,
.visible-sm,
.visible-xs {
    display: none !important
}

.hidden-xs {
    display: block
}

.row:after,
.row:before {
    display: table;
    content: " "
}

.clearfix,
.row:after {
    clear: both
}

.disptable {
    display: table;
    height: 100%
}

.dispblock {
    display: block
}

.table-cell {
    display: table-cell;
    vertical-align: middle
}

.Yoverflowhidden {
    overflow-y: hidden
}

.Xoverflowhidden {
    overflow-x: hidden
}

.overflowhidden {
    overflow: hidden
}

section {
    float: left;
    width: 100%
}

section.padding {
    padding: 70px 0
}

.overlay-custom {
    overflow: auto;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .4);
    z-index: 7;
    // display: none
}

.close-custom {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    color: hsla(0, 0%, 77%, .8);
    font-size: 26px;
    background-color: #fff;
}

.popup-custom {
    max-width: 567px;
    width: 90%;
    padding: 40px 20px;
    position: relative;
    background: #fff;
    border-radius: 20px;
    margin: 40px auto;
    transform: translateY(-50%);
    top: 44%
}

.overlay-custom .popup-custom .formdataWrapper {
    display: grid;
    justify-content: center;
    gap: 20px 0
}

.overlay-custom .popup-custom .formdataWrapper .theme-btn-wrapper {
    margin: 30px 0
}

.overlay-custom .popup-custom .formdataWrapper .theme-btn-wrapper .theme-btn {
    color: #fff;
    background-color: #4a8af8;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.overlay-custom .popup-custom .formdataWrapper .content {
    color: #333;
    font-weight: 600
}

.overlay-custom .popup-custom .formdataWrapper .closingtxtWrapper {
    display: none;
    gap: 0 10px;
    align-items: center
}

@media (max-width:640px) {
    .overlay-custom .popup-custom .formdataWrapper .closingtxtWrapper {
        gap: 0 5px
    }
}

header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 10px 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, .0117647059);
    backdrop-filter: blur(60px) brightness(100%);
    -webkit-backdrop-filter: blur(60px) brightness(100%)
}

header .upper .number-wrapper {
    margin-right: 20px;
    margin-top: 10px
}

header .upper .ctc-wrapper {
    margin-right: 20px
}

header .upper .enq-btn-wrapper a {
    display: inline-block;
    padding: 0 15px;
    color: #fff;
    background-color: #6b6b6b;
    text-transform: uppercase;
    line-height: 40px
}

header .lower .nav-links {
    text-align: right
}

header .lower .nav-links a {
    display: inline-block;
    margin-right: 20px;
    color: #000;
    position: relative
}

header .lower .nav-links a:last-child {
    margin-right: 0
}

header .lower .nav-links a.active {
    color: #6b6b6b
}

header .form-container {
    position: absolute;
    width: 300px;
    top: calc(100% + 10px);
    right: 15px;
    background-color: #efece7;
    padding: 10px;
    display: none
}

header .form-container .frmclose {
    display: none;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-size: 20px
}

header .form-container .thankyou {
    margin-bottom: 10px
}

header .madeinindia {
    font-weight: 500;
    color: #dadada;
    font-size: 10px
}

header.active {
    background-color: rgba(0, 0, 0, .0117647059);
    backdrop-filter: blur(60px) brightness(100%);
    z-index: 5
}

.call-icon svg {
    width: 20px;
    height: 20px;
    position: relative;
    right: 2px;
    top: 4px
}

.enquire-btn {
    background: #4a8af8;
    border-radius: 19px;
    color: #fff !important;
    font-size: 14px;
    padding: 10px 24px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer
}

.enquire-btn.hovtype1 {
    transition: all .8s ease-in
}

.enquire-btn.hovtype1:hover {
    background: linear-gradient(123deg, #4a8af8 -.29%, #4a8af8 39.45%, #aac9ff 45.77%, #4a8af8 52.29%, #4a8af8 101.23%);
    color: #fff
}

.eq-height-row {
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap
}

.home-banner {
    background-image: url(../images/home/photographer.jpg);
    background-size: cover;
    background-position: 100% 0
}

.bar1,
.bar2,
.bar3 {
    transition: .4s
}

.change .bar1 {
    transform: rotate(-45deg) translate(-5px, 5px);
    width: 28px !important
}

.change .bar2 {
    opacity: 0
}

.change .bar3 {
    transform: rotate(45deg) translate(-4px, -6px);
    width: 28px !important
}

.compress-logo.brand-logo {
    transform: translateY(3px) scale(1) !important
}

.brand-logo,
.compress-logo.brand-logo {
    transition: all .6s cubic-bezier(.165, .84, .44, 1)
}

.brand-logo {
    transform: scale(1) translate(10px, 8px)
}

.set-transform {
    transform: translateY(15px) !important
}

.nav-links,
.set-transform {
    transition: all .6s cubic-bezier(.165, .84, .44, 1)
}

.nav-links {
    transform: translateY(5px)
}

footer {
    float: left;
    width: 100%
}

footer a {
    color: #fff
}

footer.padding {
    padding: 70px 0
}

footer.bg-darkblue {
    background-color: #001333
}

footer .footergrid {
    display: grid;
    grid-template-columns: 40% auto;
    align-items: end
}

@media (max-width:992px) {
    footer .footergrid {
        grid-template-columns: auto;
        justify-content: center;
        gap: 30px 0
    }
}

footer .footergrid .firstcol .madeinindia {
    font-weight: 500;
    color: #dadada;
    font-size: 10px
}

footer .footergrid .firstcol .address {
    color: #c4c4c4;
    margin: 30px 0
}

footer .footergrid .firstcol .contact,
footer .footergrid .firstcol .contact a {
    color: #c4c4c4
}

@media (max-width:992px) {
    footer .footergrid .firstcol .logo>div {
        justify-content: center
    }
}

@media (max-width:992px) {
    footer .footergrid .firstcol {
        text-align: center
    }
}

footer .footergrid .secondcol {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-weight: 500
}

@media (max-width:992px) {
    footer .footergrid .secondcol {
        grid-gap: 0 80px
    }
}

@media (max-width:640px) {
    footer .footergrid .secondcol {
        grid-template-columns: auto;
        text-align: center;
        grid-gap: 20px 0
    }
}

footer .footergrid .secondcol .nav1,
footer .footergrid .secondcol .nav2,
footer .footergrid .secondcol .nav3 {
    display: flex;
    flex-direction: column;
    gap: 20px 0
}

@media (max-width:640px) {

    footer .footergrid .secondcol .nav1,
    footer .footergrid .secondcol .nav2,
    footer .footergrid .secondcol .nav3 {
        gap: 20px 0
    }
}

footer .footergrid .secondcol .nav3 .sm {
    display: grid;
    grid-template-columns: 18% auto
}

@media (max-width:640px) {
    footer .footergrid .secondcol .nav3 .sm {
        display: unset
    }
}

@media (max-width:640px) {
    footer .footergrid .secondcol .nav3 {
        flex-direction: row;
        justify-content: space-evenly
    }
}

footer .copytxt {
    margin: 40px 0 0;
    color: #c4c4c4
}

@media (max-width:640px) {
    footer .copytxt {
        margin: 20px 0 0;
        font-size: 12px
    }
}

.sc {
    color: #4a8af8
}

.secTitle {
    font-size: 40px;
    font-weight: 500;
    color: #fff;
    font-family: Outfit, sans-serif;
    text-align: center;
    margin: 20px 0;
    margin-bottom: 60px
}

@media (max-width:640px) {
    .secTitle {
        font-size: 24px;
        margin: 15px 0
    }
}

.theme-btn-wrapper {
    margin: 50px 0
}

.theme-btn {
    border: 1px solid #4a8af8;
    padding: 14px 32px;
    border-radius: 26px
}

.theme-btn.hovtype1 {
    transition: all .6s ease-in-out
}

.theme-btn.hovtype1:hover {
    background: linear-gradient(123deg, #4a8af8 -.29%, #4a8af8 39.45%, #aac9ff 45.77%, #4a8af8 52.29%, #4a8af8 101.23%);
    color: #fff
}

.theme-btn.hovtype2 {
    transition: all .3s ease-in-out
}

.theme-btn.hovtype2:hover {
    background-color: #4a8af8;
    color: #fff
}

.section-first {
    min-height: 100vh;
    // background-color: #030303
}

.section-first .qrWrapper {
    background-color: #fff;
    border-radius: 18px;
    padding: 16px;
    position: absolute;
    right: 10px;
    bottom: -40px;
    z-index: 1
}

.section-first .qrWrapper .qrdata {
    display: grid;
    gap: 15px 0
}

.section-first .qrWrapper .qrdata img {
    margin: auto
}

.section-first .qrWrapper .qrdata p {
    font-size: 14px;
    font-weight: 600
}

.section-first .home-banner-cont {
    position: absolute;
    color: #fff;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%)
}

@media (max-width:1024px) {
    .section-first .home-banner-cont {
        top: unset;
        transform: unset;
        bottom: 120px;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(-50%)
    }
}

@media (max-width:992px) {
    .section-first .home-banner-cont {
        bottom: 40px
    }
}

@media (max-width:640px) {
    .section-first .home-banner-cont {
        bottom: unset;
        top: calc(50% + 10px)
    }
}

.section-first .home-banner-cont .banner-data .prefix {
    margin-bottom: 20px
}

@media (max-width:640px) {
    .section-first .home-banner-cont .banner-data .prefix {
        font-size: 12px;
        margin-bottom: 10px
    }
}

.section-first .home-banner-cont .banner-data .title {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    margin-bottom: 20px
}

@media (max-width:640px) {
    .section-first .home-banner-cont .banner-data .title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 10px
    }
}

.section-first .home-banner-cont .banner-data .content {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #c4c4c4;
    padding-bottom: 40px
}

@media (max-width:992px) {
    .section-first .home-banner-cont .banner-data .content {
        padding-bottom: unset
    }
}

@media (max-width:640px) {
    .section-first .home-banner-cont .banner-data .content {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: unset
    }
}

.section-first .home-banner-cont .banner-data .closingtxt {
    padding-top: 20px
}

@media (max-width:640px) {
    .section-first .home-banner-cont .banner-data .closingtxt {
        font-size: 12px
    }
}

.section-first .home-banner-cont .banner-data .theme-btn-wrapper {
    margin: 40px 0 30px
}

.section-first .home-banner-cont .banner-data .theme-btn-wrapper .theme-btn {
    max-width: 200px;
    padding: 14px 24px;
    color: #fff;
    background-color: #4a8af8;
    font-weight: 600
}

.overview {
    background-color: #001333
}

.overview .secTitle {
    margin-top: 0
}

.overview .whyusWrapper {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 85px
}

@media (max-width:992px) {
    .overview .whyusWrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 0
    }
}

@media (max-width:640px) {
    .overview .whyusWrapper {
        gap: 20px 10px
    }
}

.overview .whyusWrapper .item {
    display: grid;
    grid-template-columns: auto;
    gap: 20px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff
}

@media (max-width:640px) {
    .overview .whyusWrapper .item {
        grid-template-rows: 50% auto;
        gap: 10px 0
    }
}

.overview .prevutxt {
    color: #c4c4c4
}

.features {
    background-color: #030303
}

.features .container {
    padding-bottom: 30px
}

.features .secTitle {
    margin-top: 0;
    margin-bottom: 60px
}

@media (max-width:992px) {
    .features .secTitle {
        margin-bottom: 20px
    }
}

.features .TextSliderWrapper .slick-track {
    transform: translateZ(0) !important
}

.features .TextSliderWrapper .slick-list {
    overflow: visible !important
}

.features .TextSliderWrapper .slick-slide {
    cursor: pointer;
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636
}

.features .TextSliderWrapper .slick-slide:first-of-type {
    border-top: none
}

@media (max-width:992px) {
    .features .TextSliderWrapper .slick-slide:last-of-type {
        border-bottom: none
    }
}

.features .TextSliderWrapper .slick-slide .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 46px;
    color: #fff;
    margin: 20px 0
}

@media (max-width:992px) {
    .features .TextSliderWrapper .slick-slide .title {
        text-align: center;
        margin: 20px 0 10px
    }
}

@media (max-width:640px) {
    .features .TextSliderWrapper .slick-slide .title {
        font-size: 24px;
        line-height: 32px;
        margin: 20px 0 10px
    }
}

.features .TextSliderWrapper .slick-slide .hidedesc {
    display: none;
    padding: 0 0 40px;
    color: #c4c4c4;
    transition: all .3s ease-in-out
}

@media (max-width:992px) {
    .features .TextSliderWrapper .slick-slide .hidedesc {
        display: block
    }
}

.features .TextSliderWrapper .slick-slide .hidedesc .content {
    min-height: 52px
}

@media (max-width:992px) {
    .features .TextSliderWrapper .slick-slide .hidedesc .content {
        text-align: center;
        min-height: unset
    }
}

.features .TextSliderWrapper .slick-slide .hidedesc .theme-btn-wrapper {
    margin: 40px 0 10px
}

.features .TextSliderWrapper .slick-slide .hidedesc .theme-btn-wrapper .theme-btn {
    color: #fff;
    font-weight: 600;
    font-size: 16px
}

.features .TextSliderWrapper .slick-slide .hidedesc .featuremob {
    text-align: center;
    margin: 30px 0
}

.features .TextSliderWrapper .slick-slide .hidedesc .featuremob img {
    margin: auto
}

.features .TextSliderWrapper .slick-slide.slick-current .hidedesc {
    display: block;
    transition: all .3s ease-in-out
}

.features .secondcol .featureSliderWrapper {
    background-color: #001333;
    border-radius: 32px;
    width: 85%;
    margin: auto;
    text-align: center;
    padding-top: 40px
}

@media (max-width:1024px) {
    .features .secondcol .featureSliderWrapper {
        width: 80%
    }
}

.features .secondcol .featureSliderWrapper .slide img {
    max-width: 298px;
    margin: auto
}

.purpose {
    background-color: #001333
}

.purpose .secTitle {
    margin-top: 0
}

.purpose .secTitle.title2 {
    margin-top: 50px
}

@media (max-width:992px) {
    .purpose .secTitle.title2 {
        margin-top: 30px
    }
}

.purpose .para {
    padding: 30px 0
}

.purpose .para.para2 {
    padding-bottom: 0
}

@media (max-width:992px) {
    .purpose .para {
        padding: 15px 0
    }
}

.builtwith {
    background-color: #030303
}

.builtwith.padding {
    padding: 70px 0 0
}

@media (max-width:992px) {
    .builtwith.padding {
        padding: 35px 0
    }
}

.builtwith .builtwithWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 50px;
    padding-top: 40px
}

@media (max-width:992px) {
    .builtwith .builtwithWrapper {
        grid-template-columns: auto;
        justify-content: center
    }
}

@media (max-width:640px) {
    .builtwith .builtwithWrapper {
        padding-top: 10px
    }
}

.builtwith .builtwithWrapper .item {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 15% auto;
    gap: 20px 0;
    text-align: left;
    color: #fff
}

.builtwith .builtwithWrapper .item .title {
    font-size: 24px;
    font-weight: 500
}

@media (max-width:992px) {
    .builtwith .builtwithWrapper .item .title {
        text-align: center
    }
}

.builtwith .builtwithWrapper .item .content {
    color: #c4c4c4;
    font-size: 16px
}

@media (max-width:992px) {
    .builtwith .builtwithWrapper .item .content {
        text-align: center
    }
}

.reviews {
    background-color: #030303
}

.reviews .reviewSliderWrapper {
    margin: 30px 0
}

.reviews .reviewSliderWrapper .slick-slide {
    margin: 0 20px
}

@media (max-width:1024px) {
    .reviews .reviewSliderWrapper .slick-slide {
        margin-left: unset
    }
}

.reviews .reviewSliderWrapper .slide .box {
    border: 1px solid #4a8af8;
    border-radius: 24px;
    padding: 24px;
    display: grid;
    align-items: flex-start;
    gap: 20px 0;
    min-width: 355px;
    min-height: 250px
}

@media (max-width:1440px) {
    .reviews .reviewSliderWrapper .slide .box {
        gap: 20px 0;
        min-height: 290px
    }
}

.reviews .reviewSliderWrapper .slide .box .clientinfo {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.reviews .reviewSliderWrapper .slide .box .clientinfo .clientdata {
    display: flex;
    align-items: center;
    gap: 0 20px
}

.reviews .reviewSliderWrapper .slide .box .clientinfo .clientdata .clientimg {
    width: 60px;
    height: 60px;
    border: 2px solid #4a8af8;
    border-radius: 100%
}

.reviews .reviewSliderWrapper .slide .box .clientinfo .clientdata .clientname p {
    color: #c4c4c4;
    font-size: 14px
}

.reviews .slick-dots {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    bottom: -50px;

}

.reviews .slick-dots li {
    background-color: #333;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transition: all .3s ease-in-out;
    cursor: pointer
}

.reviews .slick-dots li.slick-active {
    background-color: #4a8af8;
    width: 30px;
    border-radius: 8px
}

.reviews .slick-dots li::marker {
    font-size: 0
}

.khichiquer {
    background-color: #030303
}

.khichiquer.padding {
    padding: 50px 0
}

@media (max-width:992px) {
    .khichiquer.padding {
        padding: 35px 0
    }
}

.khichiquer .container {
    background-color: #001333;
    border-radius: 32px;
    padding: 50px 80px
}

@media (max-width:1024px) {
    .khichiquer .container {
        margin: 0 25px
    }
}

@media (max-width:640px) {
    .khichiquer .container {
        padding: 40px 15px
    }
}

.khichiquer .container .para {
    padding-top: 40px
}

.khichiquer .container .theme-btn {
    font-size: 16px
}

.answers {
    background-color: #030303
}

.answers .theme-btn {
    font-size: 16px
}

.answers .qaAccordWrapper {
    width: 80%;
    margin: auto
}

@media (max-width:992px) {
    .answers .qaAccordWrapper {
        width: 100%
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    margin: 0;
    list-style: none;
    background-color: none
}

@media (max-width:640px) {
    .answers .qaAccordWrapper .qaAccord ul.accordion-list {
        padding: 20px 0
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-color: none;
    padding: 30px;
    margin: 0 auto;
    border-top: 1px solid #363636;
    border-bottom: 1px solid #363636;
    cursor: pointer
}

@media (max-width:640px) {
    .answers .qaAccordWrapper .qaAccord ul.accordion-list li {
        padding: 30px 0
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li:first-of-type {
    border-top: none
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li.active h3:after {
    content: url(../images/home/blue-up.webp)
}

@media (max-width:640px) {
    .answers .qaAccordWrapper .qaAccord ul.accordion-list li.active h3:after {
        content: url(../images/home/blue-up-mob.webp)
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li h3 {
    font-weight: 500;
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    font-size: 24px;
    letter-spacing: .01em;
    cursor: pointer
}

@media (max-width:640px) {
    .answers .qaAccordWrapper .qaAccord ul.accordion-list li h3 {
        font-size: 18px;
        font-weight: 500
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li h3:after {
    content: url(../images/home/blue-down.webp);
    position: absolute;
    right: 0;
    top: 0;
    transition: all .3s ease-in-out
}

@media (max-width:640px) {
    .answers .qaAccordWrapper .qaAccord ul.accordion-list li h3:after {
        content: url(../images/home/blue-down-mob.webp)
    }
}

.answers .qaAccordWrapper .qaAccord ul.accordion-list li div.answer {
    position: relative;
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 0;
    transition: all 0.6s ease-in-out;
}
.answers .qaAccordWrapper .qaAccord ul.accordion-list li.active div.answer{
    transition: all 0.6s ease-in-out;
    height: auto;
    display: block;
}
.answers .qaAccordWrapper .qaAccord ul.accordion-list li div.answer p {
    position: relative;
    display: block;
    font-weight: 300;
    padding: 20px 0 0;
    cursor: pointer;
    line-height: 150%;
    margin: 0 0 15px
}

.page-title {
    background-color: #001333;
    padding: 122px 0 60px
}

.page-title h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 600;
    color: #fff;
    margin-top: 30px
}

.service-wrap {
    background-color: #000;
    padding: 70px 0 100px;
    color: #fff
}

.service-wrap h2 {
    font-family: Outfit;
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
    text-align: left;
    margin-bottom: 20px
}

.service-wrap h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px
}

.service-wrap p {
    font-family: Outfit;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #c4c4c4
}

.faqpage.service-wrap p {
    color: #c4c4c4
}

.service-wrap a {
    color: #fff
}

.service-wrap ul {
    list-style: none;
    padding-left: 27px
}

.service-wrap ul li {
    position: relative;
    color: #c4c4c4
}

.service-wrap ul li:before {
    content: "";
    position: absolute;
    left: -17px;
    top: 10px;
    width: 4px;
    height: 4px;
    background-color: #c4c4c4;
    border-radius: 100%
}

.revealOnScroll {
    opacity: 0
}

.animated {
    animation-duration: 1.2s;
    animation-fill-mode: both
}

@keyframes a {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInDown {
    animation-name: a
}

.fadeInUp {
    animation-name: b
}

@keyframes b {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInLeft {
    animation-name: c
}

@keyframes c {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInRight {
    animation-name: d
}

@keyframes d {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

@media only screen and (max-width:1024px) {
    .col-md-1 {
        width: 8.3333333333%
    }

    .col-md-2 {
        width: 16.6666666667%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.3333333333%
    }

    .col-md-5 {
        width: 41.6666666667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.3333333333%
    }

    .col-md-8 {
        width: 66.6666666667%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.3333333333%
    }

    .col-md-11 {
        width: 91.6666666667%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-12-5 {
        width: 20%
    }

    .hidden-md {
        display: none !important
    }

    .visible-md {
        display: block !important
    }
}

@media only screen and (max-width:992px) {
    .col-sm-1 {
        width: 8.3333333333%
    }

    .col-sm-2 {
        width: 16.6666666667%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.3333333333%
    }

    .col-sm-5 {
        width: 41.6666666667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.3333333333%
    }

    .col-sm-8 {
        width: 66.6666666667%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.3333333333%
    }

    .col-sm-11 {
        width: 91.6666666667%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-12-5 {
        width: 20%
    }

    .set-transform {
        transform: unset;
        transition: unset
    }

    header .form-container.show {
        top: 0 !important;
        bottom: 0 !important;
        display: block !important
    }

    header .nav-links {
        display: none;
        position: fixed;
        top: 94px;
        left: 0;
        background-color: #fff;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: unset
    }

    header .nav-links a {
        display: block !important;
        text-align: center;
        line-height: 40px !important;
        color: #fff;
        margin-top: 0;
        margin-right: 0
    }

    header .lower .nav-links a {
        margin-right: 0;
        color: #000
    }

    header .lower .nav-links a:last-child {
        margin-right: 0
    }

    header .upper .enq-btn-wrapper {
        margin-right: 50px;
        margin-top: 15px
    }

    header .upper .ctc-wrapper {
        margin-right: 5px
    }

    header .upper .number-wrapper {
        margin-right: 15px;
        margin-top: 25px
    }

    .menu-icon-mobile {
        display: block;
        width: 50px;
        padding: 14px 8px 4px;
        background-color: #6b6b6b;
        border-radius: 0;
        margin: 0;
        position: absolute;
        top: 15px;
        right: 0;
        height: 40px
    }

    .menu-icon-mobile>span {
        display: block;
        height: 2px;
        width: 100%;
        margin-bottom: 4px;
        background-color: #fff
    }

    .menu-icon-mobile.active>span:first-child {
        transform: rotate(45deg)
    }

    .menu-icon-mobile.active>span:nth-child(2) {
        transform: rotate(135deg);
        position: relative;
        top: -5px
    }

    .menu-icon-mobile.active>span:nth-child(3) {
        display: none
    }

    .hidden-sm {
        display: none !important
    }

    .visible-sm {
        display: block !important
    }

    section.padding {
        padding: 35px 0
    }

    #hid-on-mob {
        display: none !important
    }
}

@media only screen and (max-width:640px) {
    body {
        -webkit-text-size-adjust: none
    }

    .col-xs-1 {
        width: 8.3333333333%
    }

    .col-xs-2 {
        width: 16.6666666667%
    }

    .col-xs-3 {
        width: 25%
    }

    .col-xs-4 {
        width: 33.3333333333%
    }

    .col-xs-5 {
        width: 41.6666666667%
    }

    .col-xs-6 {
        width: 50%
    }

    .col-xs-7 {
        width: 58.3333333333%
    }

    .col-xs-8 {
        width: 66.6666666667%
    }

    .col-xs-9 {
        width: 75%
    }

    .col-xs-10 {
        width: 83.3333333333%
    }

    .col-xs-11 {
        width: 91.6666666667%
    }

    .col-xs-12 {
        width: 100%
    }

    .col-xs-12-5 {
        width: 20%
    }

    .hidden-xs {
        display: none !important
    }

    .visible-xs {
        display: block !important
    }

    .menu-icon-mobile {
        height: 60px;
        padding: 25px 8px 4px;
        top: -5px
    }

    header {
        padding: 5px 0 15px
    }

    header .nav-links {
        padding: 10px 0;
        top: 55px
    }

    header .form-container {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        top: 100% !important;
        bottom: -100% !important;
        width: 100% !important;
        padding: 30px 0 !important;
        transform: translate(0);
        z-index: 2;
        background-color: rgba(255, 254, 254, .97) !important
    }

    header .form-container .frmclose {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        color: #000;
        font-size: 20px
    }

    header .form-container.show {
        top: 0 !important;
        bottom: 0 !important;
        display: block !important
    }

    footer {
        padding-bottom: 30px
    }

    footer .lower {
        font-size: 10px
    }

    footer .lower div {
        text-align: center !important
    }

    .mob-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: #fff;
        font-size: 14px;
        font-weight: 600;
        z-index: 6
    }

    .mob-footer a {
        display: block;
        line-height: 30px
    }

    .mob-footer a.mob-enq-btn {
        background-color: #001333;
        background-color: #4a8af8
    }

    .mob-footer a.mob-call-btn,
    .mob-footer a.mob-enq-btn {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        padding: 10px
    }

    .mob-footer a.mob-call-btn {
        background-color: #030303
    }

    .text-xs-center {
        text-align: center
    }

    .page-title {
        padding: 99px 0 38px
    }

    .page-title h1 {
        font-size: 32px;
        line-height: 40px
    }

    .page-title img {
        width: 80px
    }

    .service-wrap h3 {
        font-size: 18px
    }

    .service-wrap p {
        font-size: 16px;
        line-height: 24px
    }

    .reviews .reviewSliderWrapper .slide .box {
        min-width: auto
    }

    .banner-data .content {
        color: #c4c4c4
    }

    .builtwith .builtwithWrapper .item {
        margin-bottom: 30px
    }
}